import { PROMOTION_TYPES } from "../../../../utils";

export const getActionTypes = (isDeactivated) => {
    return [
    { label: 'Recommend', value: PROMOTION_TYPES.recommended},
    { label: 'VOM', value: 'VOM'},
    { label: 'Top Vendor', value: 'tov'},
    isDeactivated ? { label: 'Reactivate', value: 'reactivate' } : { label: 'Deactivate', value: 'deactivate' },
    ]
}

export const getIsVendorRecommended = (promotions = []) => Boolean(promotions.find(promotion => promotion.isActive && promotion.type === PROMOTION_TYPES.recommended))
