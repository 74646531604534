import { Header } from "../header"
import { Grid, Loader } from "../../../component"
import { useGetListings } from "../../../common/query"
import { COLUMNS } from "./constant";
import { useAdminUpdateVendorMutation } from "./query";
import { toastUtil } from "../../../utils";
import { useAdminResetUserOrVendorMutation } from "../query";
import { getActionTypes } from "./utils";

export const Vendors = () => {

  const { data, isLoading } = useGetListings({
      page: 1,
      pageSize: 1000
  });

  const { mutate, isLoading: isMutatingVendor } = useAdminUpdateVendorMutation()
  const { mutate:resetUserOrVendor, isLoading: isDeactivating } = useAdminResetUserOrVendorMutation()

  if (isLoading) {
    return <div className="w-full flex justify-center items-center"><Loader/></div>
  }

  const resetUserOrVendorFunc = (selectedValue) => {
    resetUserOrVendor({ id: selectedValue.data.id, type: 'vendor', isDeactivating: !selectedValue.data.deactivated})
  }

  const handlePromotion = (selectedValue) => {
    mutate({ type: selectedValue.value, vendorId: selectedValue.id }, {
      onSuccess: () => {
        toastUtil({ message: 'Promotion created', position: 'top-center', theme: 'success' })
      },
      onError: (error) => toastUtil({ message: error.response.data.message, position: 'top-center', theme: 'failure' })

    })
  }

  const handleSave = (selectedValue) => {

    selectedValue.value === 'deactivate' || selectedValue.value === 'reactivate' ? resetUserOrVendorFunc(selectedValue) : handlePromotion(selectedValue)
  }

  return <div className="container mx-auto mt-12">
		<Header text="ADMIN.VENDORS.LABEL"/>
    <Grid
      className="mt-3"
      columns={COLUMNS}
      onSave={handleSave}
      contentData={data.vendors}
      isLoading={isMutatingVendor || isDeactivating}
      getActionTypes={getActionTypes}
    />
  </div>
}